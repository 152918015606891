import React from 'react'
import { IconsProps } from 'msp-integrations'

const SoundMuteIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 18 18'
    >
      <path d="M14.714 8.429V17l-5.436-3.345m-1.992-1.226H1V5.57h6.286L14.714 1v4M17 2.714 3.286 16.429" stroke="#fff" fill="none" />
    </svg>
  )
}

export default SoundMuteIcon
